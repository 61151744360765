import {
    EquipmentIds,
    Equipment,
    Equipments,
} from '../store/ducks/equipments/types';
interface ITranslateComponentStatusMap {
    [key: string]: IComponentStatusList;
}

interface IComponentStatusList {
    [key: string]: string;
}

interface IEquipmentComponentMap {
    [key: string]: Array<string>;
}

export const EquipmentComponentMap: IEquipmentComponentMap = Object.freeze({
    fieldbox: ['battery', 'rfidReader', 'solarPanel', 'tare'],
    parrotbox: ['battery', 'solarPanel'],
    farmbox: [],
    farmserver: [],
});

export const EquipmentTypes = [
    { key: 'fieldbox', name: 'FieldBox' },
    { key: 'parrotbox', name: 'ParrotBox' },
    { key: 'farmbox', name: 'FarmBox' },
    { key: 'farmserver', name: 'FarmServer' },
];

export const ComponentTypes = [
    { key: 'rfidReader', name: 'Leitora RFID' },
    { key: 'solarPanel', name: 'Painel Solar' },
    { key: 'tare', name: 'Tara' },
    { key: 'battery', name: 'Bateria' },
];

export const ColorStatusTranslate = [
    { color: 'green', statusTranslate: 'Funcionamento normal' },
    { color: 'yellow', statusTranslate: 'Verificar equipamento' },
    { color: 'red', statusTranslate: 'Verificar equipamento' },
    { color: 'gray', statusTranslate: 'Não disponível' },
];

export const TranslateComponentStatusMap: ITranslateComponentStatusMap =
    Object.freeze({
        battery: {
            operational: 'Operacional',
            maintenanceY: 'Manutenção',
            maintenanceR: 'Manutenção',
            unavailable: 'Não disponível',
            untracked: 'Não disponível',
        },
        farmServer: {
            operational: 'Operacional',
            slowY: 'Lentidão',
            slowR: 'Lentidão',
            unavailable: 'Não disponível',
            untracked: 'Não disponível',
        },
        rfidReader: {
            operational: 'Operacional',
            verify: 'Verificar',
            maintenance: 'Manutenção',
            unavailable: 'Não disponível',
            untracked: 'Não disponível',
        },
        solarPanel: {
            operational: 'Operacional',
            dirtyY: 'Limpeza necessária',
            dirtyR: 'Limpeza necessária',
            maintenance: 'Manutenção',
            unavailable: 'Não disponível',
            untracked: 'Não disponível',
        },
        tare: {
            operational: 'Operacional',
            dirtyY: 'Limpeza necessária',
            dirtyR: 'Limpeza necessária',
            maintenance: 'Manutenção necessária',
            unavailable: 'Não disponível',
            untracked: 'Não disponível',
        },
    });

export const mountEquipmentIdsList = (data: Equipment[]) => {
    const output: EquipmentIds[] = [];

    data.forEach(equipment => {
        const obj: EquipmentIds = {
            name: equipment.name,
            equipmentId: equipment.hardwareAddress ?? equipment.id,
        };
        output.push(obj);
    });

    return output;
};

export const mountEquipmentListWithoutTypeKey = (data: Equipments) => {
    const allEquipments: Equipment[] = [];
    const types = Object.keys(data);

    for (const type of types) {
        allEquipments.push(...data[type]);
    }
    return allEquipments;
};

import { Reducer } from 'redux';
import { EquipmentsState, EquipmentsTypes } from './types';

const INITIAL_STATE: EquipmentsState = {
    originalEquipments: {},
    equipments: {},
    equipment: { id: '', farmId: '', name: '' },
    filters: {
        equipmentIds: [],
        equipmentTypes: [],
        equipmentStatus: [],
        componentTypes: [],
        componentStatus: [],
    },
    equipmentIds: [],
};

const reducer: Reducer<EquipmentsState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EquipmentsTypes.SET_ORIGINAL_EQUIPMENTS:
            return {
                ...state,
                originalEquipments: action.payload.data,
            };
        case EquipmentsTypes.SET_EQUIPMENTS:
            return {
                ...state,
                equipments: action.payload.data,
            };
        case EquipmentsTypes.SET_EQUIPMENT:
            return {
                ...state,
                equipment: action.payload.data,
            };
        case EquipmentsTypes.SET_FILTERS:
            return {
                ...state,
                filters: action.payload.filters,
            };
        case EquipmentsTypes.SET_EQUIPMENT_IDS:
            return {
                ...state,
                equipmentIds: action.payload.equipmentIds,
            };
        default:
            return state;
    }
};

export default reducer;

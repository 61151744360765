import styled from 'styled-components';

export const CardProgressPlaceholder = styled.div`
    width: 100%;
    height: 15px;
    position: relative;
    background-color: #e5e5e5;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
`;

export const SummaryFeaturedCardDescription = styled.div`
    @media (min-width: 1280px) and (max-width: 1560px) {
        padding: 70px 0;
    }
    @media (min-width: 1561px) {
        padding: 47.75px 0;
    }
`;

export const CardSummaryDescription = styled.div`
    @media (min-width: 600px) {
        height: 50px;
    }
    @media (min-width: 1280px) and (max-width: 1560px) {
        height: 90px;
    }
`;

export const CardProgress = styled.div<{ percentage: number; color: string }>`
    width: ${props => props.percentage}%;
    height: 100%;
    position: absolute;
    background-color: ${props => props.color};
    border-radius: 5px;
`;

export const CardPercentage = styled.div`
    float: right;
    clear: both;
`;

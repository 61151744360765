import React, { useState } from 'react';
import { format } from 'date-fns';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import {
    Paper,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import StatusColumn from './component/statuscolumn/StatusColumn.component';
import HealthColumn from './component/healthcolumn/HealthColumn.component';
import Toolbar from '@material-ui/core/Toolbar';
import FilterListIcon from '@material-ui/icons/FilterList';
import Typography from '@material-ui/core/Typography';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../utils/Colors';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
    Equipment,
    Equipments,
    Filters as FiltersInterface,
} from '../../store/ducks/equipments/types';
import { ApplicationState } from '../../store';
import * as EquipmentsActions from '../../store/ducks/equipments/actions';
import Filters from './component/filters/Filters.component';
import { mountEquipmentListWithoutTypeKey } from '../../utils/EquipmentCommons';

interface StateProps {
    equipments: Equipments;
}
interface DispatchProps {
    setFilters(data: FiltersInterface): void;
}

type Props = StateProps & DispatchProps;

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'hardwareAddress', hide: true },
    { field: 'components', hide: true },
    { field: 'name', headerName: 'Nome', width: 300 },
    {
        field: 'lastConsolidation',
        headerName: 'Última verificação',
        width: 300,
    },
    {
        field: 'equipmentsHealth',
        headerName: 'Saúde do equipamento',
        width: 300,
        renderCell: params => {
            return (
                <StatusColumn
                    equipmentStatusColor={parseInt(
                        `${params.getValue(params.id, 'equipmentsHealth')}`,
                    )}
                />
            );
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 300,
        sortable: false,
        renderCell: params => {
            return (
                <HealthColumn
                    equipmentStatusColor={parseInt(
                        `${params.getValue(params.id, 'equipmentsHealth')}`,
                    )}
                />
            );
        },
    },
    {
        field: 'actions',
        headerName: ' ',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        renderCell: params => {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!params.getValue(params.id, 'components')}
                >
                    VISUALIZAR
                </Button>
            );
        },
    },
];

function buildRows(rawData: Equipment[]) {
    let count = 1;
    return rawData.map(data => {
        return {
            id: count++,
            name: data.name,
            lastConsolidation: data.lastConsolidationDateTime
                ? format(
                      new Date(data.lastConsolidationDateTime),
                      'dd/MM/yyyy hh:mm',
                  )
                : '',
            equipmentsHealth: colorToNumber(data.equipmentStatusColor),
            hardwareAddress: data.hardwareAddress,
            components: data.components,
        };
    });
}

function colorToNumber(color: string | undefined) {
    interface ColorConverter {
        [key: string]: number;
    }
    const colors: ColorConverter = {
        green: 1,
        yellow: 2,
        red: 3,
        gray: 4,
        '': 4,
    };

    return colors[color ? color : ''];
}

function EquipmentList(props: Props) {
    const { setFilters } = props;
    const [dialogFilters, setDialogFilters] = useState(false);
    const rawData = mountEquipmentListWithoutTypeKey(props.equipments);
    const [pageSize, setPageSize] = React.useState<number>(5);
    const history = useHistory();

    const handleRedirectToEquipmentDetails = (hardwareAddress: string) => {
        history.push(`/equipments/${hardwareAddress}`);
    };

    const handleResetFilters = () => {
        const filtersToApply: FiltersInterface = {
            equipmentTypes: [],
            equipmentIds: [],
            equipmentStatus: [],
            componentTypes: [],
            componentStatus: [],
            lastConsolidationStart: '',
            lastConsolidationEnd: '',
        };
        setFilters(filtersToApply);
    };

    const handleOpenDialogFilters = () => {
        setDialogFilters(true);
    };

    const handleCloseDialogFilters = () => {
        setDialogFilters(false);
    };

    const ChildrenToggleDialogFilters = (newDialogState: boolean) => {
        if (newDialogState === false) {
            handleCloseDialogFilters();
        } else {
            handleOpenDialogFilters();
        }
    };

    const mediaMatch = window.matchMedia('(min-width: 600px)');
    const toolbarStyles = {
        display: mediaMatch.matches ? 'flex' : 'block',
    };
    const toolbarActionsStyles = {
        marginTop: mediaMatch.matches ? '0' : '1em',
        display: 'flex',
        flexFlow: mediaMatch.matches ? 'initial' : 'column',
    };

    return (
        <>
            <Paper>
                <Toolbar style={toolbarStyles}>
                    <Typography
                        style={{ color: Colors.blue, flexGrow: 1 }}
                        variant="h5"
                        component="div"
                    >
                        Equipamentos <small>({rawData.length})</small>
                    </Typography>
                    <div style={toolbarActionsStyles}>
                        <Tooltip title="Limpar filtros">
                            <Button
                                variant="text"
                                style={{ color: Colors.blue }}
                                size="large"
                                onClick={handleResetFilters}
                                startIcon={<ClearAllIcon />}
                            >
                                Limpar
                            </Button>
                        </Tooltip>
                        <Tooltip title="Filtrar">
                            <Button
                                variant="text"
                                style={{ color: Colors.blue }}
                                onClick={handleOpenDialogFilters}
                                size="large"
                                startIcon={<FilterListIcon />}
                            >
                                Filtrar
                            </Button>
                        </Tooltip>
                    </div>
                </Toolbar>
                <div style={{ height: 150 + pageSize * 52, width: '100%' }}>
                    <DataGrid
                        rows={buildRows(rawData)}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={newPageSize =>
                            setPageSize(newPageSize)
                        }
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        disableColumnMenu
                        onRowClick={params =>
                            params.getValue(params.id, 'hardwareAddress') &&
                            params.getValue(params.id, 'components')
                                ? handleRedirectToEquipmentDetails(
                                      `${params.getValue(
                                          params.id,
                                          'hardwareAddress',
                                      )}`,
                                  )
                                : undefined
                        }
                    />
                </div>
            </Paper>
            <Dialog
                maxWidth={'md'}
                open={dialogFilters}
                onClose={handleCloseDialogFilters}
            >
                <DialogTitle
                    style={{ backgroundColor: Colors.blue, color: 'white' }}
                >
                    <Typography
                        style={{
                            float: 'left',
                            fontSize: '1.2em',
                            marginTop: 5,
                            fontWeight: 'bold',
                        }}
                        variant={'body1'}
                    >
                        Filtros
                    </Typography>

                    <IconButton
                        style={{ float: 'right', color: 'white' }}
                        onClick={handleCloseDialogFilters}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <Filters
                    ChildrenToggleDialogFilters={ChildrenToggleDialogFilters}
                />
            </Dialog>
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    equipments: state.equipments.equipments,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(EquipmentsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentList);

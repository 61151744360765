import { action } from 'typesafe-actions';
import {
    EquipmentsTypes,
    Equipment,
    Equipments,
    Filters,
    EquipmentIds,
} from './types';

export const setOriginalEquipments = (data: Equipments) =>
    action(EquipmentsTypes.SET_ORIGINAL_EQUIPMENTS, { data });

export const setEquipments = (data: Equipments) =>
    action(EquipmentsTypes.SET_EQUIPMENTS, { data });

export const setEquipment = (data: Equipment) =>
    action(EquipmentsTypes.SET_EQUIPMENT, { data });

export const setEquipmentIds = (equipmentIds: EquipmentIds[]) =>
    action(EquipmentsTypes.SET_EQUIPMENT_IDS, { equipmentIds });

export const setFilters = (filters: Filters) =>
    action(EquipmentsTypes.SET_FILTERS, { filters });

import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import { LinkRouter } from './SideBarStyle';
import Tooltip from '@material-ui/core/Tooltip';

type Home = {
    title: string;
    subTitle: string;
    link: string;
    icon: any;
};

export default function ListPages() {
    const [list] = useState<Array<Home>>([
        {
            title: 'Home',
            subTitle: '',
            link: '/home',
            icon: <HomeIcon color={'primary'} />,
        },
    ]);

    return (
        <List>
            {list.map((home: Home, index: number) => (
                <LinkRouter to={home.link} key={`${index} - ${home.title}`}>
                    <ListItem button>
                        <Tooltip title={home.title}>
                            <ListItemIcon>{home.icon}</ListItemIcon>
                        </Tooltip>
                        <div>
                            <ListItemText primary={home.title} />
                            {home.subTitle && (
                                <ListItemText
                                    primary={home.subTitle}
                                    style={{ marginTop: -10 }}
                                />
                            )}
                        </div>
                    </ListItem>
                </LinkRouter>
            ))}
        </List>
    );
}

import Keycloak from 'keycloak-js';

const TOKEN_REFRESH_SECONDS = 5 * 60; // 5 minutes
const authenticationURL = process.env.REACT_APP_AUTH_URL;
const KEYCLOAK_LOCAL_STORAGE_KEY = 'sls_keycloak_instance';

const keycloakOptions = {
    url: authenticationURL,
    realm: 'SLS',
    clientId: 'restricted',
};

const keycloak = Keycloak(keycloakOptions);

// refresh token loop
const scheduleRefreshToken = () => {
    keycloak.updateToken(TOKEN_REFRESH_SECONDS)
        .error(() => keycloak.logout);
};

const cachedCredentials = JSON.parse(localStorage.getItem(KEYCLOAK_LOCAL_STORAGE_KEY));

export function logout() {
    localStorage.removeItem(KEYCLOAK_LOCAL_STORAGE_KEY);
    keycloak.logout(keycloak.createLogoutUrl({ redirectUri : authenticationURL }));
}

export function authenticate(onSuccess, onError) {
    localStorage.removeItem(KEYCLOAK_LOCAL_STORAGE_KEY);
    keycloak.init(cachedCredentials || { onLoad: 'check-sso' })
        .success(((authenticated) => {
            if (authenticated) {
                // schedule token refresh
                setInterval(scheduleRefreshToken, TOKEN_REFRESH_SECONDS * 1000);

                // saves current keycloak instance to local storage
                localStorage.setItem(KEYCLOAK_LOCAL_STORAGE_KEY, JSON.stringify(keycloak));

                onSuccess();
            } else {
                keycloak.login();
            }
        }))
        .error(onError);
}

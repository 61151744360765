import styled from "styled-components";

type Props = {
    marginLeft?: number;
    marginRight?: number;
    marginTop?: number;
    marginBottom?: number;
}

export const Container = styled.div` 
    margin-top: ${(props: Props) => `${props.marginTop ? props.marginTop : 0}px`}; 
    margin-left: ${(props: Props) => `${props.marginLeft ? props.marginLeft : 0}px`};
    margin-bottom: ${(props: Props) => `${props.marginBottom ? props.marginBottom : 0}px`};
    margin-right: ${(props: Props) => `${props.marginRight ? props.marginRight : 0}px`};
`;

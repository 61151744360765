import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        background: {
            default: '#EFF0F0',
            paper: '#fff',
        },
        common: {
            black: '#bcbcbcbc',
            white: '#bcbcbcbc',
        },
        primary: {
            contrastText: '#ffffff',
            dark: '#0060B8',
            light: '#212121',
            main: '#0A5892',
        },
        secondary: {
            contrastText: '#ffffff',
            dark: '#0060B8',
            light: '#212121',
            main: '#4cafdd',
        },
        text: {
            primary: '#999999',
            secondary: '#999999',
        },
    },
});

export default theme;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { getAllEquipmentStatus } from '../home/Home.service';
import BatteryComponentDetails from './components/BatteryComponentDetails';
import RfidReaderComponentDetails from './components/RfidReaderComponentDetails';
import SolarPanelComponentDetails from './components/SolarPanelComponentDetails';
import TareComponentDetails from './components/TareComponentDetails';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Equipment } from '../../store/ducks/equipments/types';
import { ApplicationState } from '../../store';
import * as EquipmentsActions from '../../store/ducks/equipments/actions';

interface StateProps {
    equipment: Equipment;
}

interface DispatchProps {
    setEquipment(data: Equipment): void;
}

type Props = StateProps & DispatchProps;

function EquipmentDetails(props: Props) {
    const { setEquipment, equipment } = props;
    const { id }: { id: string } = useParams();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        getAllEquipmentStatus({ equipmentIds: [id] })
            .then(res => {
                let equipmentFound;
                for (const type in res.data.data) {
                    if (res.data.data[type].length) {
                        equipmentFound = res.data.data[type][0];
                    }
                }

                if (equipmentFound) {
                    setEquipment(equipmentFound);
                }
            })
            .finally(function () {
                setLoading(false);
            });
    }, [id, setEquipment]);

    const RenderComponentDetails = (componentName: string) => {
        if (componentName === 'battery') {
            return <BatteryComponentDetails equipmentData={equipment} />;
        } else if (componentName === 'tare') {
            return <TareComponentDetails equipmentData={equipment} />;
        } else if (componentName === 'solarPanel') {
            return <SolarPanelComponentDetails equipmentData={equipment} />;
        } else if (componentName === 'rfidReader') {
            return <RfidReaderComponentDetails equipmentData={equipment} />;
        }
    };

    return (
        <Container maxWidth={'xl'} style={{ marginTop: 25 }}>
            {loading && (
                <Grid spacing={3} container alignItems="center">
                    {[...Array(4)].map((x, i) => (
                        <Grid item key={`skeleton${i}`} xs={12} sm={6} md={3}>
                            <Skeleton height={150} variant={'rect'} />
                        </Grid>
                    ))}
                </Grid>
            )}

            {!loading && Object.keys(equipment).length > 0 && (
                <Grid spacing={3} container alignItems="center">
                    {equipment.components &&
                        equipment.components.map((component, key) => (
                            <Grid item key={`card${key}`} xs={12} sm={6} lg={3}>
                                {RenderComponentDetails(
                                    Object.keys(component)[0],
                                )}
                            </Grid>
                        ))}
                </Grid>
            )}
        </Container>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    equipment: state.equipments.equipment,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(EquipmentsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentDetails);

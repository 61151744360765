import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    getBackgroundColor,
    getStatusPercentageColor,
} from '../../../../utils/Colors';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

type Props = {
    equipmentStatusColor: number | null | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        healthBarPlaceholder: {
            height: 15,
            position: 'relative',
            display: 'inline-block',
            width: 150,
            marginLeft: 10,
            marginTop: 7,
            backgroundColor: '#464A53',
            verticalAlign: 'top',
            borderRadius: 10,
        },
        healthBar: {
            borderRadius: 10,
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
        },
    }),
);

export default function HealthColumn({ equipmentStatusColor }: Props) {
    const classes = useStyles();
    return (
        <>
            {equipmentStatusColor && (
                <div>
                    <LocalHospitalIcon
                        style={{ fontSize: 30 }}
                        color={'error'}
                    ></LocalHospitalIcon>{' '}
                    <span className={classes.healthBarPlaceholder}>
                        <span
                            style={{
                                backgroundColor:
                                    getBackgroundColor(equipmentStatusColor),
                                width: `${getStatusPercentageColor(
                                    equipmentStatusColor,
                                )}%`,
                            }}
                            className={classes.healthBar}
                        ></span>
                    </span>
                </div>
            )}
        </>
    );
}

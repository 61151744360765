/**
 * Action types
 */
export enum EquipmentsTypes {
    SET_ORIGINAL_EQUIPMENTS = '@equipments/SET_ORIGINAL_EQUIPMENTS',
    SET_EQUIPMENTS = '@equipments/SET_EQUIPMENTS',
    SET_EQUIPMENTS_BY_TYPE = '@equipments/SET_EQUIPMENTS_BY_TYPE',
    SET_EQUIPMENT = '@equipments/SET_EQUIPMENT',
    SET_FILTERS = '@equipments/SET_FILTERS',
    SET_EQUIPMENT_IDS = '@equipments/SET_EQUIPMENT_IDS',
}

/**
 * Data types
 */

export interface Equipment {
    id: string | number;
    name: string;
    farmId: string;
    lastConsolidationDateTime?: string | undefined;
    equipmentStatus?: string | undefined;
    equipmentStatusColor?: string | undefined;
    hardwareAddress?: string | undefined;
    components?: Array<ComponentList>;
}

export interface Equipments {
    [key: string]: Array<Equipment>;
}

export interface ComponentList {
    [key: string]: Component;
}

export interface Component {
    status: string | undefined;
    statusColor: string | undefined;
    statusDateTime: string | undefined;
}

export interface Filters {
    equipmentIds?: Array<string | number>;
    equipmentTypes?: Array<string>;
    equipmentStatus?: Array<string>;
    componentTypes?: Array<string>;
    componentStatus?: Array<string>;
    lastConsolidationStart?: string;
    lastConsolidationEnd?: string;
}

export interface EquipmentTypes {
    key: string;
    name: string;
}

export interface ComponentTypes {
    key: string;
    name: string;
}

export interface EquipmentIds {
    name: string;
    equipmentId: string | number;
}

export interface ColorStatusTranslate {
    color: string;
    statusTranslate: string;
}

/**
 * State type
 */
export interface EquipmentsState {
    readonly originalEquipments: Equipments;
    readonly equipments: Equipments;
    readonly equipment: Equipment;
    readonly filters: Filters;
    readonly equipmentIds: EquipmentIds[];
}

import React, { useState, useEffect } from 'react';
import { EquipmentList } from '../../component/Component';
import Overview from '../../component/overview/Overview.component';
import { Container, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { getAllEquipmentStatus } from './Home.service';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
    Filters,
    Equipments,
    EquipmentIds,
} from '../../store/ducks/equipments/types';
import { ApplicationState } from '../../store';
import * as EquipmentsActions from '../../store/ducks/equipments/actions';
import {
    mountEquipmentIdsList,
    mountEquipmentListWithoutTypeKey,
} from '../../utils/EquipmentCommons';

interface StateProps {
    originalEquipments: Equipments;
    equipments?: Equipments;
    filters: Filters;
    equipmentIds: EquipmentIds[];
}

interface DispatchProps {
    setOriginalEquipments(data: Equipments): void;
    setEquipments(data: Equipments): void;
    setEquipmentIds(data: EquipmentIds[]): void;
}

type Props = StateProps & DispatchProps;

function HomeComponent(props: Props) {
    const {
        originalEquipments,
        equipmentIds,
        filters,
        setEquipments,
        setOriginalEquipments,
        setEquipmentIds,
    } = props;
    const [firstLoading, setFirstLoading] = useState<boolean>(true);

    useEffect(() => {
        getAllEquipmentStatus(filters)
            .then(res => {
                if (res.data) {
                    setEquipments(res.data.data);
                    if (!Object.keys(originalEquipments).length) {
                        setOriginalEquipments(res.data.data);
                    }
                    if (!equipmentIds.length) {
                        const allEquipments = mountEquipmentListWithoutTypeKey(
                            res.data.data,
                        );
                        setEquipmentIds(mountEquipmentIdsList(allEquipments));
                    }
                }
            })
            .finally(function () {
                if (firstLoading) {
                    setFirstLoading(false);
                }
            });
        // eslint-disable-next-line
    }, [setEquipments, setEquipmentIds, filters]);

    return (
        <>
            {firstLoading && (
                <Container
                    maxWidth={'xl'}
                    style={{ paddingTop: 15, paddingBottom: 15 }}
                >
                    <Grid spacing={3} container alignItems="center">
                        <Grid
                            item
                            key={`skeleton-card-featured`}
                            xs={12}
                            md={4}
                        >
                            <Skeleton height={150} variant={'rect'} />
                        </Grid>
                        {[...Array(4)].map((x, i) => (
                            <Grid
                                item
                                key={`skeleton${i}`}
                                xs={12}
                                sm={6}
                                md={2}
                            >
                                <Skeleton height={150} variant={'rect'} />
                            </Grid>
                        ))}
                        <Grid item key={`skeleton-table`} xs={12}>
                            <Skeleton height={300} variant={'rect'} />
                        </Grid>
                    </Grid>
                </Container>
            )}

            {!firstLoading && (
                <div>
                    <Overview />

                    <Container
                        maxWidth={'xl'}
                        style={{ paddingTop: 15, paddingBottom: 15 }}
                    >
                        <EquipmentList />
                    </Container>
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    originalEquipments: state.equipments.originalEquipments,
    filters: state.equipments.filters,
    equipmentIds: state.equipments.equipmentIds,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(EquipmentsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);

import React from 'react';
import { ComponentCard } from '../../../component/card/Card.component';
import { Equipment } from '../../../store/ducks/equipments/types';

type Props = {
    equipmentData: Equipment;
};

export default function TareComponentDetails({ equipmentData }: Props) {
    return (
        <>
            <ComponentCard
                iconName={'av_timer'}
                equipmentData={equipmentData}
                componentDescription={'Tara'}
                componentName={'tare'}
            />
        </>
    );
}

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from './home/Home.component';
import EquipmentDetails from './equipmentDetails/EquipmentDetails.component';

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/home"></Redirect>
            </Route>
            <Route path="/home" component={() => <Home />} />
            <Route
                path="/equipments/:id"
                component={() => <EquipmentDetails />}
            />
        </Switch>
    );
};

export default Routes;

import { http } from '../../core/http/Http.intercepto';
import { Filters } from '../../store/ducks/equipments/types';

const KEYCLOAK_LOCAL_STORAGE_KEY = 'sls_keycloak_instance';

export function getAllEquipmentStatus(params?: Filters) {
    const keycloakData = localStorage.getItem(KEYCLOAK_LOCAL_STORAGE_KEY);
    if (keycloakData) {
        const parsedData = JSON.parse(keycloakData);
        return http.get('/allEquipmentStatus', {
            params: params,
            headers: { Authorization: `Bearer ${parsedData.token}` },
        });
    }

    return Promise.reject(new Error('Identification token not found'));
}

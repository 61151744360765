import styled from "styled-components";

export const Container = styled.div`
    padding: 30px 30px 0px 30px;
    transition: 0.5s ease-in;
    @media (max-width: 500px) {
        transition: 0.5s ease-in;
        padding: 30px 10px 0px 10px;
    }
`;

export const SubContainer = styled.div`
    padding: 0px 30px 0px 30px;
    transition: 0.5s ease-in;
    @media (max-width: 500px) {
        transition: 0.5s ease-in;
        padding: 0px 10px 0px 10px;
    }
`;

export const ContainerAlign = styled.div`
    text-align: right;
    margin-top: 15px;
    margin-bottom: 25px;
`;
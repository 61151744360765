import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { SummaryFeaturedCard, SummaryCard } from '../card/Card.component';
import { Overview as OverviewStyle } from './OverviewStyle';
import { Colors } from '../../utils/Colors';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Equipments, Component } from '../../store/ducks/equipments/types';
import { ApplicationState } from '../../store';
import * as EquipmentsActions from '../../store/ducks/equipments/actions';
import { mountEquipmentListWithoutTypeKey } from '../../utils/EquipmentCommons';
interface StateProps {
    equipments: Equipments;
}

interface DispatchProps {}

type Props = StateProps & DispatchProps;

function calculatePercentage(number: number, total: number) {
    if (!number || !total) {
        return 0;
    }
    const calc = (number / total) * 100;
    return Math.round(calc);
}

function Overview(props: Props) {
    const equipments = mountEquipmentListWithoutTypeKey(props.equipments);
    const [totalComponentsUnderMonitoring, setTotalComponentsUnderMonitoring] =
        useState<number>(0);
    const [totalComponentsWorkingNormally, setTotalComponentsWorkingNormally] =
        useState<number>(0);
    const [
        totalComponentsPendingVerificationR,
        setTotalComponentsPendingVerificationR,
    ] = useState<number>(0);
    const [
        totalComponentsPendingVerificationY,
        setTotalComponentsPendingVerificationY,
    ] = useState<number>(0);
    const [totalComponentsNotAvailable, setTotalComponentsNotAvailable] =
        useState<number>(0);

    useEffect(() => {
        setCardsData();
    });

    const setCardsData = () => {
        const componentStatusColorList: Array<string> = [];

        for (const equipment of equipments) {
            if (equipment.components) {
                for (let component of equipment.components) {
                    let componentData: Component = {
                        statusColor: '',
                        status: '',
                        statusDateTime: '',
                    };

                    if (component.tare) {
                        componentData = component.tare;
                    } else if (component.battery) {
                        componentData = component.battery;
                    } else if (component.solarPanel) {
                        componentData = component.solarPanel;
                    } else if (component.rfidReader) {
                        componentData = component.rfidReader;
                    }

                    if (
                        componentData.statusColor !== undefined &&
                        componentData.statusColor !== '' &&
                        componentData.statusColor !== null
                    ) {
                        componentStatusColorList.push(
                            componentData.statusColor,
                        );
                    }
                }
            }
        }

        setTotalComponentsUnderMonitoring(componentStatusColorList.length);

        setTotalComponentsWorkingNormally(
            componentStatusColorList.filter(
                statusColor => statusColor === 'green',
            ).length,
        );

        setTotalComponentsPendingVerificationY(
            componentStatusColorList.filter(
                statusColor => statusColor === 'yellow',
            ).length,
        );

        setTotalComponentsPendingVerificationR(
            componentStatusColorList.filter(
                statusColor => statusColor === 'red',
            ).length,
        );

        setTotalComponentsNotAvailable(
            componentStatusColorList.filter(
                statusColor => statusColor === 'gray',
            ).length,
        );
    };

    return (
        <OverviewStyle>
            <Container maxWidth="xl">
                <Grid spacing={1} container alignItems="center">
                    <Grid item xs={12} sm={12} lg={4} xl={4}>
                        <SummaryFeaturedCard
                            title="Componentes em monitoramento"
                            color={Colors.blue}
                            number={totalComponentsUnderMonitoring}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} xl={2}>
                        <SummaryCard
                            title="Componentes com funcionamento normal"
                            color={Colors.green}
                            number={totalComponentsWorkingNormally}
                            percentage={calculatePercentage(
                                totalComponentsWorkingNormally,
                                totalComponentsUnderMonitoring,
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} xl={2}>
                        <SummaryCard
                            title="Componentes com verificação pendente"
                            color={Colors.yellow}
                            number={totalComponentsPendingVerificationY}
                            percentage={calculatePercentage(
                                totalComponentsPendingVerificationY,
                                totalComponentsUnderMonitoring,
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} xl={2}>
                        <SummaryCard
                            title="Componentes com verificação pendente"
                            color={Colors.red}
                            number={totalComponentsPendingVerificationR}
                            percentage={calculatePercentage(
                                totalComponentsPendingVerificationR,
                                totalComponentsUnderMonitoring,
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} xl={2}>
                        <SummaryCard
                            title="Componentes não disponíveis"
                            color={Colors.gray}
                            number={totalComponentsNotAvailable}
                            percentage={calculatePercentage(
                                totalComponentsNotAvailable,
                                totalComponentsUnderMonitoring,
                            )}
                        />
                    </Grid>
                </Grid>
            </Container>
        </OverviewStyle>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    equipments: state.equipments.equipments,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(EquipmentsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Overview);

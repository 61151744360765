import React from 'react';
import { ComponentCard } from '../../../component/card/Card.component';
import { Equipment } from '../../../store/ducks/equipments/types';

type Props = {
    equipmentData: Equipment;
};

export default function SolarPanelComponentDetails({ equipmentData }: Props) {
    return (
        <>
            <ComponentCard
                iconName={'view_module'}
                equipmentData={equipmentData}
                componentDescription={'Painel Solar'}
                componentName={'solarPanel'}
            />
        </>
    );
}

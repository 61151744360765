import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%
  }
  body {
    font-family: Arial, Helvetica, sans-serif;
    height: 100%;
    margin: 0;
    color: #555;
    background-color:#EFF0F0;
  }

  .Toastify__toast--success {
    background: #208b3d;
  }

  .Toastify__toast--error {
    background: #d3141b;
  }
`;

type Props = {
    [key: string]: string;
};

export const Colors: Props = {
    blue: '#0A5892',
    green: '#2CA747',
    greenDense: '#165925',
    yellow: '#FEBD2C',
    yellowDense: '#9e7415',
    red: '#DD243F',
    redDense: '#8a1728',
    gray: '#8a8a8a',
    grayDense: '#4a4a4a',
};

export function getBackgroundColor(color: number | null | undefined) {
    let selectedColor;
    switch (color) {
        case 1:
            selectedColor = Colors.green;
            break;
        case 2:
            selectedColor = Colors.yellow;
            break;
        case 3:
            selectedColor = Colors.red;
            break;
        case 4:
            selectedColor = Colors.gray;
            break;
        default:
            selectedColor = '#e5e5e5';
    }

    return selectedColor;
}

export function getStatusPercentageColor(color: number | null | undefined) {
    let percentage;
    switch (color) {
        case 1:
            percentage = 100;
            break;
        case 2:
            percentage = 50;
            break;
        case 3:
            percentage = 25;
            break;
        case 4:
            percentage = 0;
            break;
        default:
            percentage = 0;
    }

    return percentage;
}

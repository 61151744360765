import React, { useState, useEffect } from 'react';
import {
    Container,
    TextField,
    Grid,
    Typography,
    Card,
    Button,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { Colors } from '../../../../utils/Colors';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
    Equipment,
    Equipments,
    EquipmentIds,
    Filters,
} from '../../../../store/ducks/equipments/types';
import { ApplicationState } from '../../../../store';
import {
    EquipmentTypes,
    ComponentTypes,
    ColorStatusTranslate,
    EquipmentComponentMap,
    mountEquipmentIdsList,
    mountEquipmentListWithoutTypeKey,
} from '../../../../utils/EquipmentCommons';
import * as EquipmentsActions from '../../../../store/ducks/equipments/actions';

interface StateProps {
    originalEquipments: Equipments;
    equipmentIds: EquipmentIds[];
    ChildrenToggleDialogFilters: (values: any) => void;
    filters: Filters;
}

interface DispatchProps {
    setFilters(data: Filters): void;
    setEquipmentIds(equipmentIds: EquipmentIds[]): void;
}

type Props = StateProps & DispatchProps;

function FiltersComponent(props: Props) {
    const {
        originalEquipments,
        equipmentIds,
        filters,
        ChildrenToggleDialogFilters,
        setFilters,
        setEquipmentIds,
    } = props;
    const [
        filteredLastConsolidationStart,
        handleFilteredLastConsolidationStart,
    ] = useState<string>('');
    const [filteredLastConsolidationEnd, handleFilteredLastConsolidationEnd] =
        useState<string>('');
    const [filteredEquipmentStatus, handleFilteredEquipmentStatus] = useState<
        Array<string>
    >([]);
    const [filteredComponentStatus, handleFilteredComponentStatus] = useState<
        Array<string>
    >([]);
    const [filteredEquipmentIds, handleFilteredEquipmentIds] = useState<
        EquipmentIds[]
    >([]);
    const [filteredEquipmentTypes, handleFilteredEquipmentTypes] = useState<
        Array<string>
    >([]);
    const [filteredComponentTypes, handleFilteredComponentTypes] = useState<
        Array<string>
    >([]);

    useEffect(() => {
        if (filters.equipmentIds) {
            handleFilteredEquipmentIds(
                equipmentIds.filter(item =>
                    filters.equipmentIds?.includes(item.equipmentId),
                ),
            );
        }
        if (filters.equipmentTypes) {
            handleFilteredEquipmentTypes(filters.equipmentTypes);
        }
        if (filters.equipmentStatus) {
            handleFilteredEquipmentStatus(filters.equipmentStatus);
        }
        if (filters.componentTypes) {
            handleFilteredComponentTypes(filters.componentTypes);
        }
        if (filters.componentStatus) {
            handleFilteredComponentStatus(filters.componentStatus);
        }
        if (filters.lastConsolidationStart) {
            handleFilteredLastConsolidationStart(
                filters.lastConsolidationStart,
            );
        }
        if (filters.lastConsolidationEnd) {
            handleFilteredLastConsolidationEnd(filters.lastConsolidationEnd);
        }
        // eslint-disable-next-line
    }, [filters]);

    const handleApplyFilters = () => {
        const filtersToApply: Filters = {
            equipmentTypes: filteredEquipmentTypes,
            equipmentIds: filteredEquipmentIds.map(equipmentId => {
                return equipmentId.equipmentId;
            }),
            equipmentStatus: filteredEquipmentStatus,
            componentTypes: filteredComponentTypes,
            componentStatus: filteredComponentStatus,
            lastConsolidationStart: filteredLastConsolidationStart,
            lastConsolidationEnd: filteredLastConsolidationEnd,
        };
        setFilters(filtersToApply);
        ChildrenToggleDialogFilters(false);
    };

    const handleResetFilters = () => {
        handleFilteredEquipmentStatus([]);
        handleFilteredEquipmentTypes([]);
        handleFilteredEquipmentIds([]);
        handleFilteredComponentStatus([]);
        handleFilteredComponentTypes([]);
        handleFilteredLastConsolidationStart('');
        handleFilteredLastConsolidationEnd('');

        setEquipmentIds(
            mountEquipmentIdsList(
                mountEquipmentListWithoutTypeKey(originalEquipments),
            ),
        );
    };

    const validateEnableComponentButtonType = (componentType: string) => {
        if (filteredEquipmentTypes.length) {
            for (const equipmentType of Object.keys(EquipmentComponentMap)) {
                if (
                    filteredEquipmentTypes.includes(equipmentType) &&
                    EquipmentComponentMap[equipmentType].includes(componentType)
                ) {
                    return true;
                }
            }
            return false;
        }
        return true;
    };

    const validateEnableEquipmentButtonType = (equipmentType: string) => {
        if (filteredComponentTypes.length) {
            for (const componentType of filteredComponentTypes) {
                if (
                    EquipmentComponentMap[equipmentType].includes(componentType)
                ) {
                    return true;
                }
            }
            return false;
        }
        return true;
    };

    const handleClickEquipmentType = (value: string) => {
        let selectedEquipmentTypes: Array<string> = [];
        if (filteredEquipmentTypes.includes(value)) {
            selectedEquipmentTypes = filteredEquipmentTypes.filter(
                equipmentType => equipmentType !== value,
            );
        } else {
            selectedEquipmentTypes = [...filteredEquipmentTypes, value];
        }
        handleFilteredEquipmentTypes(selectedEquipmentTypes);

        applyConditionsInEquipmentTypeFilterRelations(selectedEquipmentTypes);
    };

    const applyConditionsInEquipmentTypeFilterRelations = (
        selectedEquipmentTypes: Array<string>,
    ) => {
        handleFilteredEquipmentIds([]);
        if (!selectedEquipmentTypes.length) {
            const allEquipments =
                mountEquipmentListWithoutTypeKey(originalEquipments);
            setEquipmentIds(mountEquipmentIdsList(allEquipments));
        } else {
            const newEquipmentList: Equipment[] = [];

            const allEquipmentTypes = Object.keys(originalEquipments);
            allEquipmentTypes.forEach(type => {
                if (selectedEquipmentTypes.includes(type)) {
                    newEquipmentList.push(...originalEquipments[type]);
                }
            });

            setEquipmentIds(mountEquipmentIdsList(newEquipmentList));

            let componentTypesToRemove: Array<string> = [];
            for (const componentType of filteredComponentTypes) {
                componentTypesToRemove = selectedEquipmentTypes.filter(
                    equipmentType => {
                        return !EquipmentComponentMap[equipmentType].includes(
                            componentType,
                        );
                    },
                );
            }

            handleFilteredComponentTypes(
                filteredComponentTypes.filter(
                    componentType =>
                        !componentTypesToRemove.includes(componentType),
                ),
            );
        }
    };

    const handleClickComponentType = (value: string) => {
        let selectedComponentTypes: Array<string> = [];
        if (filteredComponentTypes.includes(value)) {
            selectedComponentTypes = filteredComponentTypes.filter(
                componentType => componentType !== value,
            );
        } else {
            selectedComponentTypes = [...filteredComponentTypes, value];
        }
        handleFilteredComponentTypes(selectedComponentTypes);

        applyConditionsInComponentTypeFilterRelations(selectedComponentTypes);
    };

    const applyConditionsInComponentTypeFilterRelations = (
        selectedComponentTypes: Array<string>,
    ) => {
        handleFilteredEquipmentIds([]);
        if (!selectedComponentTypes.length) {
            const allEquipments =
                mountEquipmentListWithoutTypeKey(originalEquipments);
            setEquipmentIds(mountEquipmentIdsList(allEquipments));
        } else {
            const allEquipments =
                mountEquipmentListWithoutTypeKey(originalEquipments);
            const newEquipmentList: Equipment[] = [];

            const idsInUse: Array<string | number> = [];
            allEquipments.forEach(equipment => {
                selectedComponentTypes.forEach(componentType => {
                    if (equipment.components) {
                        const equipmentComponents = equipment.components.map(
                            component => {
                                return Object.keys(component)[0];
                            },
                        );
                        if (
                            equipmentComponents.includes(componentType) &&
                            !idsInUse.includes(equipment.id)
                        ) {
                            idsInUse.push(equipment.id);
                            newEquipmentList.push(equipment);
                        }
                    }
                });
            });

            setEquipmentIds(mountEquipmentIdsList(newEquipmentList));
        }

        let equipmentTypesToRemove: Array<string> = [];
        for (const equipmentType of filteredEquipmentTypes) {
            equipmentTypesToRemove = selectedComponentTypes.filter(
                componentType => {
                    return !EquipmentComponentMap[equipmentType].includes(
                        componentType,
                    );
                },
            );
        }

        handleFilteredEquipmentTypes(
            filteredEquipmentTypes.filter(
                equipmentType =>
                    !equipmentTypesToRemove.includes(equipmentType),
            ),
        );
    };

    const handleClickEquipmentStatus = (value: string) => {
        if (filteredEquipmentStatus.includes(value)) {
            handleFilteredEquipmentStatus(
                filteredEquipmentStatus.filter(
                    equipmentStatus => equipmentStatus !== value,
                ),
            );
        } else {
            const newArr = [...filteredEquipmentStatus];
            newArr.push(value);
            handleFilteredEquipmentStatus(newArr);
        }
    };

    const handleClickComponentStatus = (value: string) => {
        if (filteredComponentStatus.includes(value)) {
            handleFilteredComponentStatus(
                filteredComponentStatus.filter(
                    componentStatus => componentStatus !== value,
                ),
            );
        } else {
            const newArr = [...filteredComponentStatus];
            newArr.push(value);
            handleFilteredComponentStatus(newArr);
        }
    };

    return (
        <>
            <Container
                style={{
                    paddingLeft: '.75em',
                    paddingRight: '.75em',
                }}
            >
                <Grid
                    container
                    style={{ paddingTop: '1em', paddingBottom: '1em' }}
                >
                    <Grid item xs={12}>
                        <Card
                            style={{
                                backgroundColor: '#fffff7',
                                padding: '.5em',
                            }}
                        >
                            <Typography
                                variant={'h5'}
                                style={{
                                    marginBottom: '.5em',
                                    color: Colors.blue,
                                }}
                            >
                                EQUIPAMENTOS
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    style={{ margin: '.5em 0' }}
                                >
                                    <Typography variant={'h6'}>
                                        Por tipo
                                    </Typography>
                                    <Grid container spacing={1}>
                                        {EquipmentTypes.map(
                                            (equipmentType, key) => {
                                                return (
                                                    <Grid
                                                        item
                                                        key={key}
                                                        xs={6}
                                                        xl={3}
                                                    >
                                                        <Button
                                                            disabled={
                                                                validateEnableEquipmentButtonType(
                                                                    equipmentType.key,
                                                                )
                                                                    ? false
                                                                    : true
                                                            }
                                                            style={{
                                                                fontSize:
                                                                    '.85em',
                                                                paddingTop:
                                                                    '1em',
                                                                paddingBottom:
                                                                    '1em',
                                                                width: '100%',
                                                            }}
                                                            variant={
                                                                'contained'
                                                            }
                                                            onClick={e =>
                                                                handleClickEquipmentType(
                                                                    equipmentType.key,
                                                                )
                                                            }
                                                            color={
                                                                filteredEquipmentTypes.includes(
                                                                    equipmentType.key,
                                                                )
                                                                    ? 'primary'
                                                                    : 'secondary'
                                                            }
                                                        >
                                                            {equipmentType.name}
                                                        </Button>
                                                    </Grid>
                                                );
                                            },
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    style={{ margin: '.5em 0' }}
                                >
                                    <Typography variant={'h6'}>
                                        Por status
                                    </Typography>
                                    <Grid container spacing={1}>
                                        {ColorStatusTranslate.map(
                                            (item, key) => {
                                                return (
                                                    <Grid
                                                        item
                                                        key={key}
                                                        xs={6}
                                                        xl={3}
                                                    >
                                                        <Button
                                                            variant={
                                                                'contained'
                                                            }
                                                            onClick={e =>
                                                                handleClickEquipmentStatus(
                                                                    item.color,
                                                                )
                                                            }
                                                            style={{
                                                                fontSize:
                                                                    '.85em',
                                                                paddingTop:
                                                                    '1em',
                                                                paddingBottom:
                                                                    '1em',
                                                                width: '100%',
                                                                color: 'white',
                                                                backgroundColor:
                                                                    !filteredEquipmentStatus.includes(
                                                                        item.color,
                                                                    )
                                                                        ? Colors[
                                                                              item
                                                                                  .color
                                                                          ]
                                                                        : Colors[
                                                                              item.color.concat(
                                                                                  'Dense',
                                                                              )
                                                                          ],
                                                            }}
                                                        >
                                                            {
                                                                item.statusTranslate
                                                            }
                                                        </Button>
                                                    </Grid>
                                                );
                                            },
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    lg={5}
                                    style={{ margin: '1em 0' }}
                                >
                                    <Typography variant={'h6'}>
                                        Por nome
                                    </Typography>
                                    <Autocomplete
                                        options={equipmentIds}
                                        getOptionLabel={option => option.name}
                                        value={filteredEquipmentIds}
                                        multiple
                                        onChange={(
                                            e: React.ChangeEvent<{}>,
                                            value: EquipmentIds[],
                                        ): void =>
                                            handleFilteredEquipmentIds(value)
                                        }
                                        noOptionsText="Nenhuma opção foi encontrada"
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                style={{ width: '100%' }}
                                                label="Equipamentos"
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    lg={7}
                                    style={{ margin: '1em 0' }}
                                >
                                    <Typography variant={'h6'}>
                                        Pela data da última verificação
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                style={{ maxWidth: '100%' }}
                                                type="datetime-local"
                                                label="De"
                                                value={
                                                    filteredLastConsolidationStart
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>,
                                                ): void =>
                                                    handleFilteredLastConsolidationStart(
                                                        e.target.value,
                                                    )
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                style={{ maxWidth: '100%' }}
                                                type="datetime-local"
                                                label="Até"
                                                value={
                                                    filteredLastConsolidationEnd
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>,
                                                ): void =>
                                                    handleFilteredLastConsolidationEnd(
                                                        e.target.value,
                                                    )
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card
                            style={{
                                backgroundColor: '#fffff7',
                                padding: '.5em',
                                marginTop: '1em',
                            }}
                        >
                            <Typography
                                variant={'h5'}
                                style={{
                                    marginBottom: '.5em',
                                    color: Colors.blue,
                                }}
                            >
                                COMPONENTES
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    style={{ margin: '.5em 0' }}
                                >
                                    <Typography variant={'h6'}>
                                        Por tipo
                                    </Typography>
                                    <Grid container spacing={1}>
                                        {ComponentTypes.map(
                                            (componentType, key) => {
                                                return (
                                                    <Grid
                                                        item
                                                        key={key}
                                                        xs={6}
                                                        xl={3}
                                                    >
                                                        <Button
                                                            disabled={
                                                                validateEnableComponentButtonType(
                                                                    componentType.key,
                                                                )
                                                                    ? false
                                                                    : true
                                                            }
                                                            style={{
                                                                fontSize:
                                                                    '.85em',
                                                                paddingTop:
                                                                    '1em',
                                                                paddingBottom:
                                                                    '1em',
                                                                width: '100%',
                                                            }}
                                                            variant={
                                                                'contained'
                                                            }
                                                            onClick={e =>
                                                                handleClickComponentType(
                                                                    componentType.key,
                                                                )
                                                            }
                                                            color={
                                                                filteredComponentTypes.includes(
                                                                    componentType.key,
                                                                )
                                                                    ? 'primary'
                                                                    : 'secondary'
                                                            }
                                                        >
                                                            {componentType.name}
                                                        </Button>
                                                    </Grid>
                                                );
                                            },
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    style={{ margin: '.5em 0' }}
                                >
                                    <Typography variant={'h6'}>
                                        Por status
                                    </Typography>

                                    <Grid container spacing={1}>
                                        {ColorStatusTranslate.map(
                                            (item, key) => {
                                                return (
                                                    <Grid
                                                        item
                                                        key={key}
                                                        xs={6}
                                                        xl={3}
                                                    >
                                                        <Button
                                                            variant={
                                                                'contained'
                                                            }
                                                            onClick={e =>
                                                                handleClickComponentStatus(
                                                                    item.color,
                                                                )
                                                            }
                                                            style={{
                                                                fontSize:
                                                                    '.85em',
                                                                paddingTop:
                                                                    '1em',
                                                                paddingBottom:
                                                                    '1em',
                                                                width: '100%',
                                                                color: 'white',
                                                                backgroundColor:
                                                                    !filteredComponentStatus.includes(
                                                                        item.color,
                                                                    )
                                                                        ? Colors[
                                                                              item
                                                                                  .color
                                                                          ]
                                                                        : Colors[
                                                                              item.color.concat(
                                                                                  'Dense',
                                                                              )
                                                                          ],
                                                            }}
                                                        >
                                                            {
                                                                item.statusTranslate
                                                            }
                                                        </Button>
                                                    </Grid>
                                                );
                                            },
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        style={{
                            textAlign: 'center',
                            paddingTop: '2em',
                        }}
                    >
                        <Button
                            variant="text"
                            onClick={handleResetFilters}
                            color="primary"
                            size="large"
                            startIcon={<ClearAllIcon />}
                        >
                            limpar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleApplyFilters}
                            color="primary"
                            size="large"
                            startIcon={<FilterListIcon />}
                        >
                            Aplicar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    originalEquipments: state.equipments.originalEquipments,
    equipmentIds: state.equipments.equipmentIds,
    filters: state.equipments.filters,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(EquipmentsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FiltersComponent);

import React from 'react';
import styled from "styled-components";
import Link from '@material-ui/core/Link';

export const LinkButton = styled(({ ...props }) => (
  <Link component="button" {...props} />
))`
  font-weight: bold;
  font-size: 13px;
`;

export const ClearFilter = styled.div` 
  text-align: right;
  margin-bottom: 20px;
`;
import React, { useState } from 'react';

import {
    CardProgressPlaceholder,
    CardProgress,
    CardPercentage,
    CardSummaryDescription,
    SummaryFeaturedCardDescription,
} from './CardStyle';

import {
    Grid,
    Card,
    CardContent,
    Typography,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import BarChartIcon from '@material-ui/icons/BarChart';
import Icon from '@material-ui/core/Icon';
import { format } from 'date-fns';
import { Colors } from '../../utils/Colors';
import { TranslateComponentStatusMap } from '../../utils/EquipmentCommons';
import { Equipment } from '../../store/ducks/equipments/types';

interface iComponentFilteredData {
    color: string;
    statusDescription: string;
    icon: string;
    statusDateTime: string;
}

interface SummaryCardProps {
    title: string;
    color: string;
    number: number;
    percentage: number;
}

interface FeaturedSummaryCardProps {
    title: string;
    color: string;
    number: number;
}

interface ComponentCardProps {
    iconName: string;
    componentName: string;
    componentDescription: string;
    equipmentData?: Equipment;
}

function ComponentCard({
    equipmentData,
    componentName,
    componentDescription,
    iconName,
}: ComponentCardProps) {
    const [dialogComponentDetails, setDialogComponentDetails] = useState(false);

    function handleOpenDialogComponentDetails() {
        setDialogComponentDetails(true);
    }

    const handleCloseDialogComponentDetails = () => {
        setDialogComponentDetails(false);
    };

    const filteredComponentData = () => {
        let rawData: any;

        if (equipmentData && equipmentData.components) {
            equipmentData.components.forEach(componentData => {
                if (Object.keys(componentData)[0] === componentName) {
                    rawData = componentData;
                }
            });
        }

        const obj: iComponentFilteredData = {
            statusDescription:
                TranslateComponentStatusMap[componentName][
                    rawData[componentName].status
                ],
            statusDateTime: rawData[componentName].statusDateTime,
            color: '',
            icon: '',
        };

        if (rawData[componentName].statusColor === 'green') {
            obj.color = Colors.green;
            obj.icon = 'checkCircle';
        } else if (rawData[componentName].statusColor === 'yellow') {
            obj.color = Colors.yellow;
            obj.icon = 'error';
        } else if (rawData[componentName].statusColor === 'red') {
            obj.color = Colors.red;
            obj.icon = 'error';
        } else if (rawData[componentName].statusColor === 'gray') {
            obj.color = Colors.gray;
            obj.icon = 'block';
        }

        return obj;
    };

    return (
        <>
            <Card
                title={'Clique para mais detalhes'}
                onClick={() => handleOpenDialogComponentDetails()}
                style={{ cursor: 'pointer' }}
            >
                <div
                    style={{
                        textAlign: 'center',
                        padding: '35px 0',
                    }}
                >
                    <div>
                        <Icon style={{ fontSize: 85 }}>{iconName}</Icon>
                    </div>

                    <div
                        style={{
                            fontWeight: 'bold',
                            fontSize: '1.3em',
                        }}
                    >
                        {componentDescription}
                    </div>
                </div>

                <div
                    style={{
                        backgroundColor: '#464A53',
                        color: 'white',
                    }}
                >
                    <CardContent style={{ padding: 10 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <div
                                    style={{
                                        fontSize: '0.7em',

                                        paddingLeft: 5,
                                    }}
                                >
                                    Equipamento
                                </div>

                                <div
                                    style={{
                                        marginTop: 10,
                                    }}
                                >
                                    <Icon
                                        style={{
                                            fontSize: 25,
                                        }}
                                    >
                                        list_alt
                                    </Icon>

                                    <span
                                        style={{
                                            verticalAlign: 'super',

                                            marginLeft: 5,
                                        }}
                                    >
                                        {equipmentData && equipmentData.name}
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div
                                    style={{
                                        fontSize: '0.7em',
                                        paddingLeft: 5,
                                    }}
                                >
                                    Monitorado em:
                                </div>

                                <div
                                    style={{
                                        marginTop: 10,
                                    }}
                                >
                                    <Icon
                                        style={{
                                            fontSize: 25,
                                        }}
                                    >
                                        today
                                    </Icon>

                                    <span
                                        style={{
                                            verticalAlign: 'super',
                                            marginLeft: 5,
                                        }}
                                    >
                                        {filteredComponentData()
                                            .statusDateTime &&
                                            format(
                                                new Date(
                                                    filteredComponentData().statusDateTime,
                                                ),
                                                'dd/MM/yyyy hh:mm',
                                            )}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </div>

                <div
                    style={{
                        backgroundColor: filteredComponentData().color,
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontSize: '1.2em',
                        padding: '20px 0',
                        textTransform: 'uppercase',
                    }}
                >
                    <Icon style={{ fontSize: 30 }}>
                        {filteredComponentData().icon}
                    </Icon>

                    <span style={{ verticalAlign: 'super', marginLeft: 7 }}>
                        {filteredComponentData().statusDescription}
                    </span>
                </div>
            </Card>

            <Dialog
                open={dialogComponentDetails}
                onClose={handleCloseDialogComponentDetails}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography
                        style={{
                            float: 'left',
                            marginTop: 12,
                            fontWeight: 'bold',
                        }}
                        variant={'body1'}
                    >
                        {componentDescription}
                    </Typography>

                    <IconButton
                        style={{ float: 'right' }}
                        aria-label="close"
                        onClick={handleCloseDialogComponentDetails}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        O status do componente é:
                        <Typography
                            variant={'overline'}
                            style={{
                                fontWeight: 'bold',
                                marginLeft: 3,
                                color: filteredComponentData().color,
                            }}
                        >
                            {filteredComponentData().statusDescription}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}

function SummaryCard({ title, color, number, percentage }: SummaryCardProps) {
    return (
        <Card style={{ marginTop: 10 }}>
            <CardContent style={{ paddingTop: 10, paddingBottom: 10 }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                    >
                        <Typography style={{ fontWeight: 'bold' }} variant="h3">
                            {number}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                        }}
                    >
                        <CardSummaryDescription>
                            <Typography variant="body1">{title}</Typography>
                        </CardSummaryDescription>
                    </Grid>

                    <Grid item xs={12}>
                        <CardProgressPlaceholder>
                            <CardProgress
                                percentage={percentage}
                                color={color}
                            />
                        </CardProgressPlaceholder>

                        <CardPercentage>
                            <Typography variant="body2">
                                {percentage}%
                            </Typography>
                        </CardPercentage>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

function SummaryFeaturedCard({
    title,
    color,
    number,
}: FeaturedSummaryCardProps) {
    return (
        <Card style={{ marginTop: 10 }}>
            <Grid container alignItems="center" style={{ textAlign: 'center' }}>
                <Grid
                    item
                    xs={12}
                    lg={5}
                    style={{
                        backgroundColor: color,
                        paddingTop: 15,
                        paddingBottom: 15,
                    }}
                >
                    <SummaryFeaturedCardDescription>
                        <Typography
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                            variant="h3"
                        >
                            {number}
                        </Typography>
                    </SummaryFeaturedCardDescription>
                </Grid>

                <Grid item xs={12} lg={7}>
                    <BarChartIcon style={{ color, fontSize: 65 }} />
                    <Typography variant="h6">{title}</Typography>
                </Grid>
            </Grid>
        </Card>
    );
}

export { SummaryCard, SummaryFeaturedCard, ComponentCard };

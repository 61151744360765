import styled from "styled-components";

type Props = {
    noPadding?: boolean;
}

export const Container = styled.div`
    color: #008DCE; 
    padding:${(props: Props) => `${props.noPadding ? 0 : 22}px`};
    transition: 0.5s ease-in;
    @media (max-width: 500px) {
        transition: 0.5s ease-in;
        padding: 22px 10px 22px 10px;
        padding:${(props: Props) => `${props.noPadding ? '0px' : '22px 10px 22px 10px'}`};
    }
`;

export const Filter = styled.div` 
    font-weight: bold;
    margin-top: -10px;
    margin-right: 50px;
    transition: 0.5s ease-in;
    @media (max-width: 500px) { 
        transition: 0.5s ease-in;
        margin-right: 20px;
    }
    @media (max-width: 350px) { 
        transition: 0.5s ease-in;
        margin-right: 0px;
    }
`;

export const NamePage = styled.div`
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
`;

export const NewPage = styled.div`
    font-size: 14px;
    margin-top: 10px;
    margin-left: 5px;
`;

export const Header = styled.div`
    color: #008DCE; 
    margin-bottom: 40px;
`;

export const Page = styled.span` 
    font-size: 0.8em;
`;

export const SubPage = styled(Page)`  
    color: #7bbae6;
`;

export const Title = styled.div`
    font-size: 2.0em;
    margin-top: 20px; 
    color: #0A4F9A;
`;
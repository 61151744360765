import styled from "styled-components";
import { drawerWidthMax, drawerWidthMin } from '../../utils/DrawerWidth';

type Props = {
    open: boolean;
}

export const Container = styled.div` 
   /* display: flexbox; */
`;

export const Main = styled.div` 
    flex-grow: 1;
    transition: 0.2s ease-in;
    margin-left: ${(props: Props) => `${props.open ? drawerWidthMax : drawerWidthMin}px`};
`;
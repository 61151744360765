import { createStore, Store } from 'redux';
import { EquipmentsState } from './ducks/equipments/types';
import rootReducer from './ducks/rootReducer';

export interface ApplicationState {
    equipments: EquipmentsState;
}

const store: Store<ApplicationState> = createStore(rootReducer);

export default store;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Menu from '../container/Menu.component';


const Routes = () => {
    return (
        <Switch>
            <Route path='/' component={() => <Menu />} />
        </Switch>
    )
}

export default Routes;


import React from 'react';
import { ComponentCard } from '../../../component/card/Card.component';
import { Equipment } from '../../../store/ducks/equipments/types';

type Props = {
    equipmentData: Equipment;
};

export default function BatteryComponentDetails({ equipmentData }: Props) {
    return (
        <>
            <ComponentCard
                iconName={'battery_charging_full'}
                equipmentData={equipmentData}
                componentName={'battery'}
                componentDescription={'Bateria'}
            />
        </>
    );
}

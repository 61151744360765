import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getBackgroundColor } from '../../../../utils/Colors';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

type Props = {
    equipmentStatusColor: number | null | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            color: 'white',
            padding: '15px 0',
        },
        statusDescription: {
            lineHeight: 1.9,
            verticalAlign: 'top',
            marginLeft: 10,
            fontWeight: 'bold',
            fontSize: '1.2em',
        },
    }),
);

export default function StatusColumn({ equipmentStatusColor }: Props) {
    const classes = useStyles();
    return (
        <>
            {equipmentStatusColor && (
                <div
                    style={{
                        backgroundColor:
                            getBackgroundColor(equipmentStatusColor),
                        width: 300,
                    }}
                    className={classes.content}
                >
                    <span>
                        {equipmentStatusColor === 1 && (
                            <div>
                                <CheckCircleIcon />
                                <span className={classes.statusDescription}>
                                    Operacional
                                </span>
                            </div>
                        )}
                        {equipmentStatusColor === 2 && (
                            <div>
                                <ErrorIcon />
                                <span className={classes.statusDescription}>
                                    Verificação necessária
                                </span>
                            </div>
                        )}
                        {equipmentStatusColor === 3 && (
                            <div>
                                <ErrorIcon />
                                <span className={classes.statusDescription}>
                                    Verifcação necessária
                                </span>
                            </div>
                        )}
                        {equipmentStatusColor === 4 && (
                            <div>
                                <CancelIcon />
                                <span className={classes.statusDescription}>
                                    Não disponível
                                </span>
                            </div>
                        )}
                    </span>
                </div>
            )}
        </>
    );
}

import React from 'react';
import { GlobalStyle } from './core/GlobalStyle';
import Router from './core/Router';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Theme from './core/Theme';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
    autoClose: 6000,
    draggable: false,
});

function App() {
    return (
        <MuiThemeProvider theme={Theme}>
            <GlobalStyle />
            <Router />
        </MuiThemeProvider>
    );
}

export default App;

import React from 'react';
import { logout } from '../../utils/keycloak';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import logo from '../../../src/assets/bosch-logo-5.png';
import Grid from '@material-ui/core/Grid';
import InputIcon from '@material-ui/icons/Input';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import { Img, Span } from './AppBarStyle';
import { drawerWidthMax } from '../../utils/DrawerWidth';
import { useHistory } from 'react-router-dom';

const KEYCLOAK_LOCAL_STORAGE_KEY = 'sls_keycloak_instance';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            marginLeft: theme.spacing(7) + 5,
            backgroundColor: '#fff',
            boxShadow: 'none',
            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            width: `calc(100% - ${theme.spacing(7) + 5}px)`,
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidthMax,
            width: `calc(100% - ${drawerWidthMax}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    }),
);

type Props = {
    open: boolean;
};

export default function MiniDrawer({ open }: Props) {
    const history = useHistory();
    const classes = useStyles();

    const token = localStorage.getItem(KEYCLOAK_LOCAL_STORAGE_KEY);
    const userName = token
        ? JSON.parse(token).idTokenParsed.farmName
        : 'Usuário Padrão';

    const redirectToHome = () => {
        history.push('/');
    };

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Toolbar>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Img
                        src={logo}
                        onClick={() => redirectToHome()}
                        alt="logo"
                        style={{ cursor: 'pointer' }}
                    />
                    <div>
                        <Span>{userName}</Span>
                        <IconButton color={'primary'}>
                            <PersonIcon />
                        </IconButton>
                        <IconButton color={'primary'} onClick={() => logout()}>
                            <InputIcon />
                        </IconButton>
                    </div>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

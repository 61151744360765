import styled from "styled-components";

export const Img = styled.img`
    width: 100px;
    height: 25px;
`;

export const Span = styled.span`
    color: #008DCE;
    margin-right: 10px;
`;

import React, { useState } from 'react';
import { Menu } from '../component/Component';
import { Container, Main } from './menu/MenuStyle';
import Routes from './Routes.container';

export default function MenuC() {
    const [open, setOpen] = useState<boolean>(false);

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    window.addEventListener('resize', function (event) {
        let currentHideNav: boolean = document.body.clientWidth <= 760;
        currentHideNav ? setOpen(false) : setOpen(true);
    });

    return (
        <Container>
            <Menu.AppBar open={open} />
            <Menu.Drawer open={open} onClick={handleDrawerOpen} />
            <Main open={open}>
                <Menu.ToolBar />
                <Routes />
            </Main>
        </Container>
    );
}

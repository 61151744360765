import AppBar from './appbar/AppBar.component';
import Drawer from './drawer/Drawer.component';
import ToolBar from './toolbar/ToolBar.component';
import ModalC from './modal/Modal.component';
import ModalDelete from './modal/ModalDelete.component';
import HeaderC from './header/Header.component';
import HeaderRegister from './header/HeaderRegister.component';
import ButtonC from './button/Button.component';
import ButtonLink from './button/ButtonLink.component';
import ButtonForm from './button/ButtonForm.component';
import ButtonClean from './button/ButtonClean.component';

import {
    FormInput,
    FormSelect,
    FormControl,
    ContainerRadio,
    LabelForm,
    FormRadio,
    FormControlRadio,
} from './form/InputStyle';

import ActionModal from './modal/ActionModal.component';
import { Container, SubContainer, ContainerAlign } from './container/Container';
import {
    SummaryCard,
    SummaryFeaturedCard,
    ComponentCard,
} from './card/Card.component';

import ProgressC from './progress/Progress.component';
import SwitchC from './switch/Switch.component';
import EquipmentListC from './equipmentList/EquipmentList.component';

export const Menu = { Drawer, AppBar, ToolBar };
export const Modal = { ModalC, ActionModal, ModalDelete };
export const Header = { HeaderC, HeaderRegister };
export const Button = { ButtonC, ButtonLink, ButtonClean, ButtonForm };
export const Form = {
    FormInput,
    FormSelect,
    FormControl,
    ContainerRadio,
    LabelForm,
    FormRadio,
    FormControlRadio,
};

export const Component = { Container, SubContainer, ContainerAlign };
export const Card = { SummaryCard, SummaryFeaturedCard, ComponentCard };
export const Progress = ProgressC;
export const Switch = SwitchC;
export const EquipmentList = EquipmentListC;
